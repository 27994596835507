import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress, Container, Typography, Divider, Button } from '@mui/material';
import { getAboutUs } from '../../services/repositories/AboutUsService'; 
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import topBanner from '../../assets/images/topbanner.webp';
import { CSSTransition } from 'react-transition-group';
import { useNavigate } from 'react-router-dom';
import './AboutUs.css'; 

function AboutUs() {
  const [aboutUs, setAboutUs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedItemId, setExpandedItemId] = useState(null);
  const descriptionRefs = useRef({});
  const navigate = useNavigate();

  const handleTitleClick = (id) => {
    setExpandedItemId((prevId) => {
      const newId = prevId === id ? null : id;
      
      if (newId !== null && descriptionRefs.current[newId]) {
        setTimeout(() => {
          descriptionRefs.current[newId].scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300); 
      }

      return newId;
    });
  };

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const data = await getAboutUs(navigate);
        setAboutUs(data.data);
      } catch (error) {
        console.error('Error fetching about us:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAboutUs();
  }, [navigate]);

  return (
    <Box sx={{ backgroundColor: '#fff8f0' }}> 
      <ParallaxHeader title="About Us" backgroundImage={topBanner} />
      
      <Container sx={{ mt: 4, padding: { xs: '0', sm: '0 16px', md: '0 32px' } }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              minHeight: '100vh',
              width: '100%', 
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
            }}
          >
            {aboutUs.map((item, index) => (
              <React.Fragment key={item.id}>
                {index !== 0 && <Divider sx={{ width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }} />} 
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '16px 0',
                    width: '100%',
                    backgroundColor: 'rgba(137,117,44,0.12)'
                  }}
                  onClick={() => handleTitleClick(item.id)}
                >
                  <Typography
                    variant="h2"
                    sx={{ 
                      textAlign: 'center', 
                      margin: '16px 0', 
                      fontSize: '1.5rem',
                      width: '100%',
                    }} 
                  >
                    {item.title}
                  </Typography>
                </Box>
                
                <CSSTransition
                  in={expandedItemId === item.id}
                  timeout={300}
                  classNames="expand"
                  unmountOnExit
                >
                  <Box
                    ref={(el) => (descriptionRefs.current[item.id] = el)}
                    sx={{ 
                      padding: '16px', 
                      textAlign: 'center', 
                      maxWidth: 'calc(100% - 32px)',
                      width: '100%',
                    }}
                  >
                    {/* <Divider sx={{ mb: 2 }} /> */}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.description }}
                      style={{ 
                        maxHeight: '300px', 
                        overflowY: 'auto', 
                        // marginBottom: '16px',
                        padding: '0 16px', 
                      }} 
                    />

                    {index === aboutUs.length - 1 && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-evenly',
                          mt: '6px',
                          mb: '5px',
                          width: '100%',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/booking')}
                          sx={{
                            borderRadius: '20px',
                            backgroundColor: '#52070c',
                            '&:hover': {
                              backgroundColor: '#3f0405',
                            },
                            width: '80px',
                            fontSize: '0.5rem', 
                          }}
                        >
                          Booking
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/customer-service')}
                          sx={{
                            borderRadius: '20px',
                            backgroundColor: '#52070c',
                            '&:hover': {
                              backgroundColor: '#3f0405',
                            },
                            width: '80px', 
                            fontSize: '0.5rem',
                          }}
                        >
                          Customer Service
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => navigate('/help-center')}
                          sx={{
                            borderRadius: '20px',
                            backgroundColor: '#52070c',
                            '&:hover': {
                              backgroundColor: '#3f0405',
                            },
                            width: '80px', 
                            fontSize: '0.5rem', 
                          }}
                        >
                          FAQ
                        </Button>
                      </Box>
                    )}

                  </Box>
                </CSSTransition>
              </React.Fragment>
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default AboutUs;
