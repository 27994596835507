import React from 'react';
import { Box, Typography } from '@mui/material';

function ParallaxHeader({ title, backgroundImage }) {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        height: { xs: '130px', sm: '300px' },
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontFamily: 'PT Sans, sans-serif',
          textAlign: 'center',
          fontSize: { xs: '2rem', sm: '4rem' }, 
          marginTop: { xs: '60px', sm: '70px' }, 
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default ParallaxHeader;
