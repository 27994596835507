import React, { useState } from 'react';
import { Box, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import image1 from '../../assets/images/mid3/mid3a.jpg'; 
import image2 from '../../assets/images/mid3/mid3b.jpg'; 

function Mid3() {
  const navigate = useNavigate();
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleExploreClick = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/promo');
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '20px', marginTop: '100px', flexDirection: { xs: 'column', md: 'row' } }}>
      <Box sx={{ width: { md: '35%' }, paddingLeft: '30px', paddingRight: '30px' }}>
        <Typography variant="h1" sx={{ textAlign: 'left', marginBottom: '20px', fontSize: '50px' }}>
          Join our Monthly & Annual Draw
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'PT Sans, sans-serif', textAlign: 'left', marginBottom: '10px', fontSize: '16px' }}>
          All eligible entries will be entered into the Grand Annual Draw.
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: 'PT Sans, sans-serif', textAlign: 'left', marginBottom: '40px', fontSize: '16px' }}>
          Ask staff or check Terms on how to also qualify for the monthly draw.
        </Typography>
        <Typography 
          variant="h6" 
          sx={{ 
            fontFamily: 'PT Sans, sans-serif', 
            textAlign: 'left', 
            fontSize: '16px', 
            textDecoration: 'underline',
            cursor: 'pointer'  // Make it look clickable
          }}
          onClick={handleExploreClick}  // Add click handler
        >
          EXPLORE OUR PROMOTIONS
        </Typography>
      </Box>

      <Box sx={{ width: { xs: '100%', md: '65%' }, display: 'flex', justifyContent: 'space-between' , flexDirection: { xs: 'column', md: 'row' } }}>
        <Box sx={{ width: 'auto', paddingRight: { xs: '0px', md: '30px' }, paddingTop: { xs: '30px', md: '0px' }}}>
          <img 
            src={image1} 
            alt="Vertical Content" 
            style={{ width: '100%', height: 'auto', objectFit: 'contain', display: 'block' }}
          />
        </Box>
        
        <Box sx={{ width: 'auto', paddingTop: { xs: '30px', md: '0px' }}}>
          <img 
            src={image2} 
            alt="Horizontal Content" 
            style={{ width: '100%', height: 'auto', objectFit: 'contain', display: 'block' }}
          />
        </Box>
      </Box>

      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Mid3;
