import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import ParallaxHeader from '../parallaxheader/ParallaxHeader'; 
import logo2 from '../../assets/images/logo2.webp';
import topBanner from '../../assets/images/topbanner.webp';
import { getCustomerServiceLink } from '../../services/repositories/CustomerServiceLinkService';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start', 
  height: '100vh',
  backgroundColor: '#fff8f0',
  textAlign: 'center',
  padding: theme.spacing(2),
  paddingTop: theme.spacing(4), 
}));

const StyledButton = styled(Button)(({ theme, isLoading }) => ({
  borderRadius: '20px',
  backgroundColor: '#52070c',
  color: '#fff',
  fontFamily: 'PT Sans, sans-serif',
  marginTop: theme.spacing(2),
  position: 'relative', 
  '&:hover': {
    backgroundColor: '#3f0405',
  },
  '&:disabled': {
    backgroundColor: '#3f0405',
  },
}));

const SpinnerWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

function CustomerService() {
  const [link, setLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    const fetchLink = async () => {
      try {
        const data = await getCustomerServiceLink();
        setLink(data.data.value);
      } catch (error) {
        console.error('Error fetching customer service link:', error);
        setError('Failed to fetch customer service link.');
      } finally {
        setLoading(false);
      }
    };

    fetchLink();
  }, []);

  const handleButtonClick = () => {
    if (link) {
      setButtonLoading(true);
      window.open(link, '_blank');
      setButtonLoading(false); 
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <ParallaxHeader title="Customer Service" backgroundImage={topBanner} />
      <StyledBox>
        <img src={logo2} alt="Customer Service Logo" style={{ width: '200px', marginBottom: '50px' }} />
        <Divider sx={{ width: '80%', my: 2, borderColor: 'rgba(137, 117, 44, 0.3)' }} />
        <Typography variant="h6" sx={{ fontFamily: 'PT Sans, sans-serif', marginBottom: '20px', marginTop: '20px' }}>
          To find out more information, please contact customer service during normal working hours (9.00am-9.00pm): 00:00-23:59
        </Typography>
        <Divider sx={{ width: '80%', my: 2, borderColor: 'rgba(137, 117, 44, 0.3)', marginBottom: '50px' }} />
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <StyledButton
            variant="contained"
            onClick={handleButtonClick}
            disabled={buttonLoading}
          >
            {buttonLoading ? (
              <SpinnerWrapper>
                <CircularProgress size={24} color="inherit" />
              </SpinnerWrapper>
            ) : (
              'Click here to contact us today'
            )}
          </StyledButton>
        )}
      </StyledBox>
    </Box>
  );
}

export default CustomerService;
