import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css';

import img1 from '../../assets/images/mid1/mid01c.webp';
import img2 from '../../assets/images/mid1/mid02c.webp';
import img3 from '../../assets/images/mid1/mid03c.webp';
import img4 from '../../assets/images/mid1/mid04c.webp';
import img5 from '../../assets/images/mid1/mid05c.webp';
import img6 from '../../assets/images/mid1/mid06c.webp';
import img7 from '../../assets/images/mid1/mid07c.webp';
import img8 from '../../assets/images/mid1/mid08c.webp';
import img9 from '../../assets/images/mid1/mid09c.webp';
import img10 from '../../assets/images/mid1/mid10c.webp';
// import img11 from '../../assets/images/mid1/mid11c.webp';
import img12 from '../../assets/images/mid1/mid12c.webp';
import img13 from '../../assets/images/mid1/mid13c.webp';
import img14 from '../../assets/images/mid1/mid14c.webp';
import img15 from '../../assets/images/mid1/mid15c.webp';
import img16 from '../../assets/images/mid1/mid16c.webp';
import img17 from '../../assets/images/mid1/mid17c.webp';
import img18 from '../../assets/images/mid1/mid18c.webp';

const images = [
  { src: img1, alt: 'Image 1', text: 'Urban Blooms' },
  { src: img2, alt: 'Image 2', text: 'Wedding' },
  { src: img3, alt: 'Image 3', text: 'Akshaya Tritiya' },
  { src: img4, alt: 'Image 4', text: 'Anniversary' },
  { src: img5, alt: 'Image 5', text: 'Baby Births' },
  { src: img6, alt: 'Image 6', text: 'Birthday' },
  { src: img7, alt: 'Image 7', text: 'Deepavali' },
  { src: img8, alt: 'Image 8', text: 'Engagement' },
  { src: img9, alt: 'Image 9', text: "Father's Day" },
  { src: img10, alt: 'Image 10', text: 'Eid' },
  // { src: img11, alt: 'Image 11', text: 'Lunar New Year' },
  { src: img12, alt: 'Image 12', text: "Mother's Day" },
  { src: img13, alt: 'Image 13', text: "Deepavali" },
  { src: img14, alt: 'Image 14', text: "Akshaya Tritiya" },
  { src: img15, alt: 'Image 15', text: "Vinayak Charturthi" },
  { src: img16, alt: 'Image 16', text: "Navrathri" },
  { src: img17, alt: 'Image 17', text: "Karthigai Deepam" },
  { src: img18, alt: 'Image 18', text: "Tamil New Year" },
];

const PrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(137,117,44, 0.5)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '24px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9664;
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(137,117,44, 0.5)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '24px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9654;
  </div>
);

const ImageCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: '100vw',
        overflow: 'hidden',
        position: 'relative',
        height: '500px', 
      }}
    >
      <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              height: '500px', 
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end', 
            }}
          >
            <img
              src={image.src}
              alt={image.alt}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                display: 'block',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                right: '0',
                textAlign: 'center',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0)', 
                padding: '36px',
                boxSizing: 'border-box',
                zIndex: 10,
                fontSize: '32px',
                overflow: 'hidden', 
              }}
            >
              {image.text}
            </Box>
          </Box>
        ))}
      </Slider>
      <PrevArrow
        onClick={() => sliderRef.current.slickPrev()}
      />
      <NextArrow
        onClick={() => sliderRef.current.slickNext()}
      />
    </Box>
  );
};

export default ImageCarousel;
