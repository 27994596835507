import React, { useState } from "react";
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import banner from '../../assets/images/topbanner.webp';
import PopUpDialog from '../../components/popupdialog/PopUpDialog'; 
import { useNavigate } from 'react-router-dom'; 

const theme = createTheme({
  typography: {
    fontFamily: 'PT Sans, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(152, 16, 33)',
          color: 'white',
          width: '120px',
          height: '40px',
          borderRadius: '0',
          fontSize: '17px',
          '&:hover': {
            backgroundColor: '#c4923b',
          },
        },
      },
    },
  },
});

function ExploreButton({ onClick }) {
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" onClick={onClick}>
        EXPLORE
      </Button>
    </ThemeProvider>
  );
}

const Topbanner = () => {
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleOpenDialog = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/booking');
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: { xs: 'auto', lg: 'auto' }, 
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' }, 
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
    >
      
      <img
        src={banner}
        alt="Top Banner"
        style={{
          display: { xs: 'block', lg: 'none' }, 
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: { xs: 'left', lg: 'center' },
          justifyContent: 'center',
          width: { xs: '100%', lg: 'auto' },
          paddingLeft: { xs: '50px', lg: '0px' },
          position: { xs: 'relative', lg: 'absolute' },
          top: { xs: 'auto', lg: '50%' },
          transform: { xs: 'none', lg: 'translateY(-50%)' }, 
          color: '#fff',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#52070c',
            fontSize: { xs: '2rem', lg: '3.5rem' }, 
            margin: 0,
            paddingBottom: '15px',
            paddingTop: { xs: '20px' , lg: '0px'},
            textAlign: { xs: 'left', lg: 'center' },
          }}
        >
          Mustafa Jewellery
        </Typography>

        <Typography
          variant="h2"
          sx={{
            color: '#52070c',
            fontSize: { xs: '1rem', lg: '1.5rem' }, 
            margin: 0,
            paddingBottom: '40px',
            textAlign: { xs: 'left', lg: 'center' },
          }}
        >
          Crafted Brilliance For Every Occasion
        </Typography>

        <ExploreButton onClick={handleOpenDialog} />
      </Box>

      <PopUpDialog open={isDialogOpen} onClose={handleCloseDialog} />

      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Topbanner;
