import React, { useState } from 'react';
import { Box, Container, Grid, Tabs, Tab, Divider } from '@mui/material';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.webp';
import BankStatement from '../bankstatement/BankStatement';
import WithdrawalList from '../withdrawallist/WithdrawalList';

const Statement = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff8f0', minHeight: '100vh' }}>
      <ParallaxHeader title="Statement" backgroundImage={topBanner} />

      <Box sx={{ flexGrow: 1 }}>
        <Container>
            <Grid container spacing={4} sx={{ backgroundColor: 'transparent' }}>
            <Grid item xs={12} sx={{ backgroundColor: 'transparent' }}>
                <Tabs
                value={currentTab}
                onChange={handleTabChange}
                centered
                aria-label="statement tabs"
                sx={{ backgroundColor: 'transparent', borderRadius: '30px' }}
                >
                <Tab label="Deposit Statement" />
                <Tab label="Withdrawal History" />
                </Tabs>
                <Divider sx={{ mt: 4 }} />
            </Grid>
            </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12}>
              {currentTab === 0 && <BankStatement isStatement={true} />}
              {currentTab === 1 && <WithdrawalList isStatement={true} />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Statement;
