import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { getBankStatement } from '../../services/repositories/BankStatementService';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff8f0',
  minHeight: '100vh',
  paddingTop: theme.spacing(4),
}));

const StyledTable = styled(Table)(({ theme }) => ({
  fontFamily: 'PT Sans, sans-serif',
  '& th': {
    fontWeight: 'bold',
    fontFamily: 'PT Sans, sans-serif',
    color: '#52070c',
    textAlign: 'left',
  },
  '& td': {
    // fontFamily: 'PT Sans, sans-serif',
    verticalAlign: 'middle',
    height: '60px',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    color: '#52070c',
    fontFamily: 'PT Sans, sans-serif',
    padding: 0,
    border: 'none',
    background: 'none',
    textDecoration: 'underline',
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
}));

const TableWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(4),
}));

function BankStatement({ isStatement = false }) {
  const [statements, setStatements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBankStatement = async () => {
      try {
        const data = await getBankStatement(navigate);
        setStatements(data.data);
      } catch (error) {
        console.error('Error fetching bank statement:', error);
        setError('Failed to fetch bank statement.');
      } finally {
        setLoading(false);
      }
    };

    fetchBankStatement();
  }, [navigate]);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <StyledBox>
      <Container sx={{ mt: isStatement?0:6 }}>
        {!isStatement && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
            <StyledButton onClick={() => navigate(-1)}>Back</StyledButton>
          </Box>
        )}

        {error ? (
          <Typography color="error" sx={{ fontFamily: 'PT Sans, sans-serif' }}>{error}</Typography>
        ) : (
          <TableWrapper>
            <TableContainer component={Paper}>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Credit</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Description</TableCell> {/* New column header */}
                    <TableCell>Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statements.map((statement) => (
                    <TableRow key={statement.id}>
                      <TableCell>₹{statement.credit}</TableCell>
                      <TableCell>₹{statement.debit}</TableCell>
                      <TableCell>{statement.transactionType.descriptionCustomer}</TableCell> 
                      <TableCell>₹{statement.balance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </TableWrapper>
        )}
      </Container>
    </StyledBox>
  );
}

export default BankStatement;
