import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, Fab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import Appbar from './components/appbar/Appbar';
import Topbanner from './components/topbanner/Topbanner';
import Mid1 from './components/mid1/Mid1';
import Mid2 from './components/mid2/Mid2';
import Mid3 from './components/mid3/Mid3';
import Mid4 from './components/mid4/Mid4';
import Mid5 from './components/mid5/Mid5';
import Footer from './components/footer/Footer';
import PopupDialog from './components/popupdialog/PopUpDialog';
import Booking from './components/booking/Booking';
import Promo from './components/promo/Promo';
import AboutUs from './components/aboutus/AboutUs';
import HelpCenter from './components/helpcenter/HelpCenter';
import Profile from './components/profile/Profile';
import CustomerService from './components/customerservice/CustomerService';
import Records from './components/records/Records';
import BookingDetail from './components/bookingdetail/BookingDetail';
import Deposit from './components/deposit/Deposit';
import BankStatement from './components/bankstatement/BankStatement';
import Withdrawal from './components/withdrawal/Withdrawal';
import AddBank from './components/addbank/AddBank';
import WithdrawalList from './components/withdrawallist/WithdrawalList';
import SignInRewards from './components/signinrewards/SignInRewards';
import ProductList from './components/productlist/ProductList';
import Promotions from './components/promotions/Promotions';
import Statement from './components/statement/Statement';
import Downline from './components/downline/Downline';
import ScamAlert from './components/scamalert/ScamAlert';
import UseIntersectionObserver from './hooks/UseIntersectionObserver';
import actionImage from './assets/images/action.png';

function AppContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [topbannerRef, topbannerVisible] = UseIntersectionObserver({ threshold: 0.5 });
  const [mid1Ref, mid1Visible] = UseIntersectionObserver({ threshold: 0.1 });
  const [mid2Ref, mid2Visible] = UseIntersectionObserver({ threshold: 0.5 });
  const [mid3Ref, mid3Visible] = UseIntersectionObserver({ threshold: 0.5 });
  const [mid4Ref, mid4Visible] = UseIntersectionObserver({ threshold: 0.5 });
  const [mid5Ref, mid5Visible] = UseIntersectionObserver({ threshold: 0.5 });

  const [dialogOpen, setDialogOpen] = useState(location.pathname === '/');
  const [loginReminderOpen, setLoginReminderOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleFabClick = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/customer-service');
    } else {
      setLoginReminderOpen(true);
    }
  };

  const handleCloseLoginReminder = () => {
    setLoginReminderOpen(false);
  };

  return (
    <div className="App" style={{ position: 'relative' }}>
      <Appbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div ref={topbannerRef} className={`slide-up ${topbannerVisible ? 'visible' : ''}`}>
                <Topbanner />
              </div>
              <div ref={mid1Ref} className={`slide-up ${mid1Visible ? 'visible' : ''}`}>
                <Mid1 />
              </div>
              <div ref={mid2Ref} className={`slide-up ${mid2Visible ? 'visible' : ''}`}>
                <Mid2 />
              </div>
              <div ref={mid3Ref} className={`slide-up ${mid3Visible ? 'visible' : ''}`}>
                <Mid3 />
              </div>
              <div ref={mid4Ref} className={`slide-up2 ${mid4Visible ? 'visible' : ''}`}>
                <Mid4 />
              </div>
              <div ref={mid5Ref} className={`slide-up ${mid5Visible ? 'visible' : ''}`}>
                <Mid5 />
              </div>
              <Footer />
              {dialogOpen && <PopupDialog open={dialogOpen} onClose={handleDialogClose} />}
            </>
          }
        />
        <Route path="/booking" element={<Booking />} />
        <Route path="/promo" element={<Promo />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/help-center" element={<HelpCenter />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/customer-service" element={<CustomerService />} />
        <Route path="/records" element={<Records />} />
        <Route path="/booking-detail" element={<BookingDetail />} />
        <Route path="/deposit" element={<Deposit />} />
        <Route path="/bank-statement" element={<BankStatement />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/add-bank" element={<AddBank />} />
        <Route path="/withdrawal-list" element={<WithdrawalList />} />
        <Route path="/sign-in-rewards" element={<SignInRewards />} />
        <Route path="/product-list" element={<ProductList />} />
        <Route path="/promotions" element={<Promotions />} /> 
        <Route path="/statement" element={<Statement />} /> 
        <Route path="/downline" element={<Downline />} /> 
        <Route path="/scam-alert" element={<ScamAlert />} /> 
      </Routes>

      <Fab
        color="primary"
        aria-label="action"
        onClick={handleFabClick}
        sx={{
          position: 'fixed',
          bottom: 40,
          left: 20,
          width: 56,
          height: 56,
          backgroundImage: `url(${actionImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'rgba(255,255,255, 0.85)',
          border: 'none',
          borderRadius: 100,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '&:focus': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
          '&:active': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      />

      <Dialog
        open={loginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const theme = createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          strokeLinecap: 'round',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#52070c',
    },
  },
});

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </Router>
  );
}

export default App;
