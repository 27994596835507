import React from 'react';
import { Dialog, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material'; 

const CustomDialog = ({ open, onClose, title, children, onSubmit, submitLabel, submitLoading, submitDisabled }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          backgroundColor: 'rgba(255, 248, 240, 0.9)',
          padding: 2,
        },
      }}
    >
      <div style={{ fontFamily: 'PT Sans', fontSize: '24px', margin: '16px' }}>
        <h1>{title}</h1>
      </div>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          sx={{
            color: '#800000',
            fontFamily: 'PT Sans',
            fontWeight: 'bold',
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          disabled={submitDisabled || submitLoading}
          sx={{
            color: '#52070c',
            fontFamily: 'PT Sans',
            fontWeight: 'bold',
          }}
        >
          {submitLoading ? <CircularProgress size={24} /> : submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
