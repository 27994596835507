import React, { useRef } from 'react';
import Slider from 'react-slick';
import { Box } from '@mui/material';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import p1 from '../../assets/images/promotions/p1.jpg';
import p2 from '../../assets/images/promotions/p2.jpg';
import p3 from '../../assets/images/promotions/p3.jpg';
import p4 from '../../assets/images/promotions/p4.jpg';
import p5 from '../../assets/images/promotions/p5.jpg';
import p6 from '../../assets/images/promotions/p6.jpg';
import p7 from '../../assets/images/promotions/p7.jpg';
import p8 from '../../assets/images/promotions/p8.jpg';
import p9 from '../../assets/images/promotions/p9.jpg';
import p10 from '../../assets/images/promotions/p10.jpg';
import p11 from '../../assets/images/promotions/p11.jpg';
import p12 from '../../assets/images/promotions/p12.jpg';
import p13 from '../../assets/images/promotions/p13.jpg';
import p14 from '../../assets/images/promotions/p14.jpg';
import p15 from '../../assets/images/promotions/p15.jpg';
import p16 from '../../assets/images/promotions/p16.jpg';
import p17 from '../../assets/images/promotions/p17.jpg';
import p18 from '../../assets/images/promotions/p18.jpg';

const images = [
  p1, p2, p3, p4, p5, p6, p7, p8, p9, p10,
  p11, p12, p13, p14, p15, p16, p17, p18
];

const PrevArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(0,0,0, 0.0)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '12px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9664;
  </div>
);

const NextArrow = ({ onClick }) => (
  <div
    onClick={onClick}
    style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      zIndex: 2,
      cursor: 'pointer',
      background: 'rgba(0,0,0, 0.0)',
      color: 'white',
      borderRadius: '0',
      padding: '10px',
      fontSize: '12px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    &#9654;
  </div>
);

const PromotionCarousel = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        // backgroundColor: 'white',
        padding: '16px 0',
      }}
    >
      <Slider ref={sliderRef} {...settings}>
        {images.map((src, index) => (
          <Box key={index} sx={{ position: 'relative' }}>
            <img
              src={src}
              alt={`Promotion ${index + 1}`}
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            />
          </Box>
        ))}
      </Slider>
      <PrevArrow
        onClick={() => sliderRef.current.slickPrev()}
      />
      <NextArrow
        onClick={() => sliderRef.current.slickNext()}
      />
    </Box>
  );
};

export default PromotionCarousel;
