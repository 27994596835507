import React from 'react';
import { Box, Typography } from '@mui/material';
import ImageCarousel from '../imagecarousel/ImageCarousel';

function Mid1() {
  return (
    <Box
      sx={{
        paddingTop: '100px',
        paddingBottom: '100px',
        textAlign: 'center',
        width: '100%',
        overflowX: 'hidden', 
        boxSizing: 'border-box', 
      }}
    >
      <Typography 
        variant="h1"
        sx={{
          paddingBottom: '50px', 
          fontSize: '40px'
        }}
      >
        For All Occasions
      </Typography>

      <ImageCarousel />
    </Box>
  );
}

export default Mid1;
