import React from 'react';
import { Box, Typography } from '@mui/material';
import backgroundImage from '../../assets/images/mid4/mid4bg.png';
import leftImage from '../../assets/images/mid4/mid4left.webp';
import Icon1 from '../../assets/images/mid4/mid4a.webp'; 
import Icon2 from '../../assets/images/mid4/mid4b.webp';
import Icon3 from '../../assets/images/mid4/mid4c.webp';
import Icon4 from '../../assets/images/mid4/mid4d.webp';
import Icon5 from '../../assets/images/mid4/mid4e.webp';

const iconsAndText = [
  { icon: Icon1, text: 'TRUSTED' },
  { icon: Icon2, text: 'LIFETIME SERVICE' },
  { icon: Icon3, text: 'PURCHASE GUARANTEE' },
  { icon: Icon4, text: '1000+ DESIGN' },
  { icon: Icon5, text: 'BEST GOLD RATE' },
];

function Mid4() {
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center',
        marginTop: '100px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '50px', 
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <Box 
          sx={{ 
            width: { xs: '100%', md: 'auto' }, 
            padding: 0,
            margin: 0,
            overflow: 'hidden',
          }}
        >
          <img 
            src={leftImage} 
            alt="" 
            style={{ 
              width: '100%', 
              height: '100%', 
              display: 'block',
              margin: 0 
            }}
          />
        </Box>

        <Box 
          sx={{ 
            width: '55%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            alignContent: 'center',
            margin: '100px',
          }}
        >
          <Typography variant="h1" sx={{ fontSize: '40px', marginBottom: '30px', textAlign: 'center', color: 'white' }}>
            Our Timeless History
          </Typography>
          <Typography variant="h6" sx={{ fontFamily: 'PT Sans, sans-serif', fontSize: '18px', textAlign: 'left', color: 'white' }}>
            At Mustafa Jewellery, we believe that every piece of jewellery tells a unique story and is a reflection of one’s personality and style. We offer an extensive range of traditional and contemporary jewellery designs that cater to every generation.
            <br /><br />
            Our commitment to quality, authenticity and customer satisfaction has earned us the trust and loyalty of our customers, who return to us time and time again for their jewellery needs.
            <br /><br />
            We take pride in our rich legacy of providing you with exquisite and timeless pieces of jewellery that stand the test of time. We continue to uphold our reputation for quality and craftsmanship, delivering stunning creations that embody elegance and sophistication.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap', 
          rowGap: { xs: '20px', md: '0px' },
          width: '100%',
          marginBottom: '50px',
        }}
      >
        {iconsAndText.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: { xs: index < 3 ? '33.33%' : '50%', md: '18%' }, 
              marginBottom: { xs: index < 3 ? '0' : '10px', md: '0' }, 
            }}
          >
            <img
              src={item.icon}
              alt={`Icon ${index + 1}`}
              style={{ width: '50px', height: '50px', marginBottom: '10px' }} 
            />
            <Typography variant="body2" sx={{ fontFamily:'PT Sans, sans-serif', fontSize:'16px', textAlign: 'center', color: 'white' }}>
              {item.text}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default Mid4;
