import React, { useState } from 'react';
import { Box, Container, Grid, Button, Typography } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
// import ParallaxHeader from '../parallaxheader/ParallaxHeader';
// import topBanner from '../../assets/images/promotions/topbanner.png';
import promoBanner from '../../assets/images/promotions/pbanner.jpg';
import ImageCarousel from '../promotioncarousel/PromotionCarousel'; 
import Topbanner from '../promotiontopbanner/PromotionTopBanner'; 
import './Promotions.css';

function Promotions() {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleExpand = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const renderExpandableButton = (index, section, text, content) => (
    <Box key={index} sx={{ marginBottom: '8px' }}>
      <Button
        onClick={() => handleExpand(`${section}-${index}`)}
        sx={{
          width: '100%',
          textAlign: 'left',
          fontSize: '20px',
          justifyContent: 'flex-start',
          backgroundColor: 'transparent',
          color: '#52070c',
          fontFamily: 'PT Sans, serif',
          fontWeight: 'bold',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#c4923b'
          },
        }}
      >
        {expandedSection === `${section}-${index}` ? (
          <span>
            <span style={{ color: '#c4923b' }}>−</span> {text}
          </span>
        ) : (
          `+ ${text}`
        )}
      </Button>
      <CSSTransition
        in={expandedSection === `${section}-${index}`}
        timeout={300}
        classNames="expandable-button"
        unmountOnExit
      >
        <Box sx={{ paddingLeft: '16px', marginTop: '8px' }}>
          <Typography variant="body2" sx={{ color: '#52070c', fontFamily: 'PT Sans, sans-serif', fontWeight: 'bold' }}>
            {content}
          </Typography>
        </Box>
      </CSSTransition>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* <ParallaxHeader title="Promotions" backgroundImage={topBanner} /> */}
      <Topbanner/>
      <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' } }}>
        <Container>
          <Box
            component="img"
            src={promoBanner}
            alt="Promotional Banner"
            sx={{
              width: '100%',
              display: 'block',
              margin: '0 auto',
              marginBottom: '24px',
            }}
          />

          <ImageCarousel />

          <Typography variant="h1" sx={{ fontSize: '40px', marginBottom: '24px', textAlign: 'left', color: '#52070c', fontFamily: 'PT Sans, sans-serif' }}>
            Winner List
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
            {renderExpandableButton(0, 'september23', 'September Lucky Draw Winners 2023', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Prasad Belakar<br/>
                  2. Veera Venkata Satya Raghava Rao Gavara<br/>
                  3. Pandurangan Rajagopal<br/>
                  4. Rakesh Kumar Gupta<br/>
                  5. Ganesh Venkatraman<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(1, 'october23', 'October Lucky Draw Winners 2023', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Mir Ebrahim<br/>
                  2. N. Karunakaran<br/>
                  3. Tamizh K<br/>
                  4. Prabhu Mahadevan<br/>
                  5. Bee Kuan Lim<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(2, 'november23', 'November Lucky Draw Winners 2023',
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Ramya Subramanian<br/>
                  2. Mollah Sanaulla<br/>
                  3. Hossen Jamal<br/>
                  4. Paramasivam Krishna Kumar<br/>
                  5. Miah Mohammad Chan<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(3, 'december23', 'December Lucky Draw Winners 2023', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Nisha Prasad<br/>
                  2. Sengoden Boopathi<br/>
                  3. Suneel Podapati<br/>
                  4. Haripriya Rajesh<br/>
                  5. Shanmugam Rajesekaran<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(4, 'january24', 'January Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Fajle Rabbi<br/>
                  2. Md Kamal Hossen<br/>
                  3. Chandrasen Guduru<br/>
                  4. G Sai Kiran Reddy<br/>
                  5. Himanshu Shukla<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(5, 'february24', 'February Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Annmalaiarumugam Arumugam<br/>
                  2. Sarker Yeasin<br/>
                  3. Srikrishnan Gurumoorthy Iyer<br/>
                  4. Mukund Phatak<br/>
                  5. Subramani Thiyagu<br/>
                </Typography>
              </Box>
            )}

            </Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: 'left' }}>
            {renderExpandableButton(6, 'march24', 'March Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: { xs: '0', sm: '8px' }, color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Sudhakara Rao<br/>
                  2. Ayitha Sindhura<br/>
                  3. Gayan Athauda<br/>
                  4. Narendran Kumar<br/>
                  5. Ahmed Rasel Rana<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(7, 'april24', 'April Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Venkat Raman<br/>
                  2. Ravikumar Kuppaswamy<br/>
                  3. Mohammed Ghouse Mohammed Ibrahim<br/>
                  4. Ali Mohammed Rajob<br/>
                  5. Naushin Rahman<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(8, 'may24', 'May Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Sundar RK<br/>
                  2. Karishma Yoheesan<br/>
                  3. Govindha Konar Ramachandran<br/>
                  4. Karthigayan Krishnamoorthy<br/>
                  5. Dorji Penjor<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(9, 'june24', 'June Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Thanjai Nehrudasan Anand<br/>
                  2. Mohamed Ismail Akbarsha<br/>
                  3. Muthu Kumar<br/>
                  4. Farej Farej<br/>
                  5. Rajamani Kannan<br/>
                </Typography>
              </Box>
            )}

            {renderExpandableButton(10, 'july24', 'July Lucky Draw Winners 2024', 
              <Box>
                <Typography variant="body2" sx={{ marginTop: '8px', color: '#141617', fontFamily: 'PT Sans, sans-serif', fontSize: '18px' }}>
                  1. Bharani Dharan Pushparaj<br/>
                  2. Yellapu Sunitha<br/>
                  3. Hossain Amir<br/>
                  4. Prema Nagumotu<br/>
                  5. Narasimhan Subramaniam<br/>
                </Typography>
              </Box>
            )}

            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Promotions;
