import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper, Typography, CircularProgress, SvgIcon } from '@mui/material';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { getProfile } from '../../services/repositories/ProfileService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.webp';
import { useNavigate } from 'react-router-dom';

const ArrowDownIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12 16l-6-6h12l-6 6z" />
  </SvgIcon>
);

const ArrowRightIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M8 12l6-6v12l-6-6z" />
  </SvgIcon>
);

function Downline() {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const level2Names = ['Rajesh Kumar', 'Pooja Sharma', 'Anil Verma', 'Neha Singh'];
  const level3Names = [
    ['Ravi Patel', 'Amit Desai', 'Priya Mehra', 'Kiran Gupta'],
    ['Suman Reddy', 'Vikram Singh', 'Meera Sethi', 'Rajeev Bhat'], 
    ['Siddharth Rao', 'Anita Menon', 'Arjun Iyer', 'Nandini Kumar'],
    ['Rohit Yadav', 'Sneha Agarwal', 'Aman Singh', 'Tanvi Desai']
  ];

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(navigate);
        setProfile(data.data);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff8f0', minHeight: '100vh', fontFamily: 'PT Sans' }}>
      <ParallaxHeader title="My Downline" backgroundImage={topBanner} />
      <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' }, display: 'flex', justifyContent: 'flex-start' }}>
        <Container>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={8}>
              <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                <Typography variant="h6" sx={{ marginBottom: '16px', fontSize: '24px', fontWeight: 'bold', textAlign: 'left', paddingLeft: '20px' }}>
                  Downline
                </Typography>

                <TreeView
                  aria-label="downline tree"
                  defaultCollapseIcon={<ArrowDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  sx={{ flexGrow: 1, overflowY: 'auto', pl: 2, textAlign: 'left' }}
                >
                  <TreeItem 
                    itemId="1" 
                    label={<Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{profile.name}</Typography>}
                  >
                    {profile.customer.showMlm === 1 ? (
                      level2Names.map((name, index) => (
                        <TreeItem 
                          key={index} 
                          itemId={`2-${index}`} 
                          label={<Typography sx={{ fontSize: '18px' }}>{name}</Typography>}
                        >
                          {level3Names[index].map((subName, subIndex) => (
                            <TreeItem 
                              key={subIndex} 
                              itemId={`3-${index}-${subIndex}`} 
                              label={<Typography sx={{ fontSize: '16px' }}>{subName}</Typography>}
                            />
                          ))}
                        </TreeItem>
                      ))
                    ) : (
                      <TreeItem 
                        itemId="empty" 
                        label={<Typography sx={{ fontSize: '18px' }}>No Downline</Typography>}
                      />
                    )}
                  </TreeItem>
                </TreeView>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Downline;
