import React, { useState, useEffect } from 'react';
import { Box, Container, Grid, Paper, Typography, CircularProgress, Button, Divider, Modal, IconButton, AppBar, Toolbar } from '@mui/material';
import { getProfile } from '../../services/repositories/ProfileService'; 
import { TIMER_CONFIG } from '../../config/TimeConfig';
import { postSubmitBooking } from '../../services/repositories/SubmitBookingService';
import ParallaxHeader from '../parallaxheader/ParallaxHeader';
import topBanner from '../../assets/images/topbanner.webp';
import CustomDialog from '../customdialog/CustomDialog'; 
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group'; 
import CloseIcon from '@mui/icons-material/Close';
import './Booking.css';

function Booking() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFailedDialogOpen, setIsFailedDialogOpen] = useState(false);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showTerms, setShowTerms] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isRan, setIsRan] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await getProfile(navigate);
        setProfile(data.data);

        if (data.data.customer.popupTitle) {
          setOpenPopup(true);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleCheckBooking = async () => {
    setIsDisabled(true);
    setLoading(true);
    try {
      // if(profile.customer.disabledBooking !== 1 && profile.customer.disabledBooking !== '1'){

        const data = await postSubmitBooking(navigate);

        setTimeout(() => {
          setLoading(false);

          if(!isRan){
            if (data.code === 2000) {
              setIsRan(true);
              navigate('/booking-detail');
            } else {
              if(data.title){
                setErrorTitle(data.title);
              } else {
                setErrorTitle('Note');
              }
              setErrorMessage(data.message);
              setIsFailedDialogOpen(true);
              setIsDisabled(false);
            }
          }
        }, TIMER_CONFIG.submitting);

      // } else {
      //   setErrorTitle(profile.customer.disabledBookingTitle);
      //   setErrorMessage(profile.customer.disabledBookingDescription);
      //   setIsFailedDialogOpen(true);
      // }
    } catch (error) {
      setLoading(false);
      console.error('Error during booking:', error.message || error);
      setErrorTitle('Error');
      setErrorMessage('An unexpected error occurred.');
      setIsFailedDialogOpen(true);
      setIsDisabled(false);
    }
  };

  const handleFailedDialogClose = () => {
    setIsFailedDialogOpen(false);
  };

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center', 
          justifyContent: 'center',
          minHeight: '100vh',
          width: '100%', 
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!profile) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', 
        }}
      >
        <Typography variant="h6">No Data Available</Typography>
      </Box>
    );
  }

  const fields = [
    { label: 'Wallet Balance', value: `₹${profile.customer.availablePurchaseAmt}` },
    { label: "Today's Voucher", value: `₹${profile.customer.todayCommAmt}` },
    { label: 'Total Withdrawable Amount', value: `₹${profile.customer.lockedAmt}` },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#fff8f0', minHeight: '100vh' }}>
      <ParallaxHeader title="Booking" backgroundImage={topBanner} />
      <Box sx={{ flexGrow: 1, padding: { xs: '16px', sm: '32px' } }}>
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6}>
              <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                  {fields[0].label}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {fields[0].value}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                  {fields[1].label}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {fields[1].value}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                  {fields[2].label}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {fields[2].value}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Paper elevation={3} sx={{ padding: '16px', backgroundColor: '#fff', borderRadius: '30px' }}>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                Ongoing Task Progress
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {`${profile.customer.todayOrderNum} / ${profile.customer.dailyOrderMax}`}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Grid item xs={12}>
        <Divider sx={{ my: 4, width: '100%' }} />
      </Grid>
      <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
        <Button
          disabled={isDisabled}
          variant="contained"
          color="primary"
          onClick={handleCheckBooking}
          sx={{ 
            borderRadius: '20px',
            backgroundColor: '#52070c', 
            '&:hover': {
              backgroundColor: '#3f0405', 
            }
          }}
        >
          Book Now
        </Button>

        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Button
            onClick={() => setShowTerms(!showTerms)}
            sx={{
              fontSize: '12px',
              color: '#52070c',
              textDecoration: 'underline',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Rules Description
          </Button>
          <CSSTransition
            in={showTerms}
            timeout={300}
            classNames="terms"
            unmountOnExit
          >
            <Box sx={{ mt: 2, mb:7, ml:{ xs: 5, sm: 10, md: 15, lg: 20 }, mr:{ xs: 5, sm: 10, md: 15, lg: 20 }, padding: '16px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)' }}>
              <Typography variant="body2" sx={{ fontSize: '12px', textAlign: 'left', padding: '30px' }}>
                1. Each mobile phone number can only be registered to one account. To prevent misuse or illegal activities, the platform has implemented specific rules to ensure proper usage. The system will automatically match orders for agents when processing gradings. After completing daily gradings, funds can be withdrawn to the pre-set or linked bank account. For top-ups, only the account holder is permitted to transfer money; transfers from others or anonymous sources might compromise the account's security.
                <br /><br />
                2. Members must complete the assigned gradings each day.
                <br /><br />
                3. All gradings are randomly assigned by the system, so once you receive a grading, it cannot be changed, canceled, or skipped.
                <br /><br />
                4. Each order is provided by different merchants, so profits will vary accordingly.
                <br /><br />
                5. The platform is not responsible if you transfer funds to the wrong bank account or one with expired validation time.
                <br /><br />
                6. A withdrawal request can only be made after all daily gradings have been completed.
                <br /><br />
                <strong>Note:</strong> Please avoid using the same bank account information to register multiple accounts on this platform to prevent data errors. Once a bank account is linked to an account, it cannot be changed unless there is an issue with the bank account that prevents withdrawals.
              </Typography>
            </Box>
          </CSSTransition>
        </Box>
      </Box>
      <CustomDialog
        open={isFailedDialogOpen}
        onClose={handleFailedDialogClose}
        title={errorTitle ?? "Note"}
        onSubmit={handleFailedDialogClose}
        submitLabel="Ok"
      >
        <p>{errorMessage}</p>
      </CustomDialog>

      <Modal
        open={openPopup}
        onClose={handlePopupClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            width: '80%',
            maxWidth: '800px', 
            maxHeight: '80vh',
            bgcolor: 'background.paper',
            boxShadow: 5,
            overflowY: 'auto',  
            overflowX: 'hidden', 
            outline: 'none',
            '@media (max-width: 600px)': {
              width: '95%',
            },
          }}
        >
          {profile && profile.customer.popupTitle && (
            <>
              <AppBar position="static" sx={{ backgroundColor: 'rgba(137,117,44,0.7)' }} elevation={0}>
                <Toolbar>
                  <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {profile.customer.popupTitle}
                  </Typography>
                  <IconButton edge="end" color="inherit" onClick={handlePopupClose}>
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Box sx={{ padding: '16px' }}>
                <div dangerouslySetInnerHTML={{ __html: profile.customer.popupDesc }} />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default Booking;
