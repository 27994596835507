import React, { useState } from 'react';
import { Box, Typography, Button, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './Mid2.css'; 
import image1 from '../../assets/images/mid2/mid2a.webp';
import image2 from '../../assets/images/mid2/mid2b.webp';

const getFormattedDate = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();

  return `${day}-${month}-${year}`;
};

const theme = createTheme({
  typography: {
    fontFamily: 'PT Sans, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#c4923b',
          color: 'white',
          borderRadius: '0',
          fontSize: '17px',
          borderBottom: '1px solid white', 
          '&:hover': {
            backgroundColor: 'rgb(152, 16, 33)',
          },
        },
      },
    },
  },
});

function EnquiryButton({ onClick }) {
  return (
    <ThemeProvider theme={theme}>
      <Button variant="contained" onClick={onClick}>MAKE AN ENQUIRY</Button>
    </ThemeProvider>
  );
}

const Mid2 = () => {
  const navigate = useNavigate();
  const [isLoginReminderOpen, setIsLoginReminderOpen] = useState(false);

  const handleEnquiryClick = () => {
    if (localStorage.getItem('accessToken')) {
      navigate('/customer-service');
    } else {
      setIsLoginReminderOpen(true);
    }
  };

  const handleCloseLoginReminder = () => {
    setIsLoginReminderOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      <Box //Left Column
        className="column" 
        style={{ backgroundImage: `url(${image1})`, width: '100%', minHeight: '600px'}}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#ffffff',
            fontSize: '3.5rem',
            margin: 0,
            paddingBottom: '50px',
            fontFamily: 'PT Sans, sans-serif',
          }}
        >
          Try It On At The Store
        </Typography>

        <EnquiryButton onClick={handleEnquiryClick}/>
      </Box>

      <Box //Right Column
        className="column" 
        style={{ backgroundImage: `url(${image2})`, width: '100%' }}
      >
        <Box 
          sx={{
            backgroundColor: 'rgba(137, 117, 44, 0.6)',
            padding: '20px',
            borderRadius: '8px',
            width: 'calc(100% - 90px)',  
            height: 'calc(100% - 90px)',  
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', 
            position: 'relative',        
            backdropFilter: 'blur(10px)',
          }}
        >
          <Typography variant="h1" sx={{ color: 'white', marginBottom: '70px', fontSize: '50px', fontFamily: 'PT Sans, sans-serif', textAlign: 'center' }}>
            Gold Exchange Rates
          </Typography>
          
          <Typography variant="body1" sx={{ color: 'white', marginBottom: '30px', textAlign: 'left', fontSize: '16px', fontFamily: 'PT Sans, sans-serif' }}>
            GOLD RATE IN INDIAN RUPEES
          </Typography>

          <Divider sx={{ width: '100%', marginBottom: '10px', borderColor: 'white' }} />

          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', color: 'white', marginBottom: '30px' , marginTop: '20px', fontSize: '18px'}}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif'  }}>
              22k-916 Jewellery
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif'  }}>
              6,695
            </Typography>
          </Box>

          <Divider sx={{ width: '100%', marginBottom: '10px', borderColor: 'white' }} />
          
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', color: 'white', marginBottom: '30px', marginTop: '20px', fontSize: '18px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif' }}>
              24k-999 Jewellery
            </Typography>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontFamily: 'PT Sans, sans-serif' }}>
              7,304
            </Typography>
          </Box>
          
          <Divider sx={{ width: '100%', marginBottom: '10px', borderColor: 'white' }} />
          
          <Typography variant="body2" sx={{ color: 'white', marginBottom: '30px', marginTop: '30px', textAlign: 'left', fontSize: '16px', fontFamily: 'PT Sans, sans-serif' }}>
            Last Updated on: {getFormattedDate()} 23:03:13 PM (IST)
          </Typography>
          
          <Typography variant="body1" sx={{ color: 'white', fontWeight: 'bold', textAlign: 'left', fontSize: '15px', fontFamily: 'PT Sans, sans-serif' }}>
            Gold Price Disclaimer:
          </Typography>
          
          <Typography variant="body2" sx={{ color: 'white', textAlign: 'left', fontSize: '15px', fontFamily: 'PT Sans, sans-serif' }}>
            Please note that the gold rates displayed here are indicative. The prices in-store may differ due to timing differences in updates and rapid market fluctuations. We appreciate your understanding.
          </Typography>
        </Box>
      </Box>

      <Dialog
        open={isLoginReminderOpen}
        onClose={handleCloseLoginReminder}
        aria-labelledby="login-reminder-dialog-title"
        aria-describedby="login-reminder-dialog-description"
      >
        <DialogTitle id="login-reminder-dialog-title">{"Please Log In"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="login-reminder-dialog-description">
            You need to be logged in to continue. Please log in to access this feature.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLoginReminder} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Mid2;
