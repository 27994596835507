import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postLogin } from '../../services/repositories/LoginService';
import CustomDialog from '../customdialog/CustomDialog';

function LoginDialog({ open, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleLogin = async () => {
    setLoading(true);
    setError(''); 

    try {
      const response = await postLogin({
        email,
        password,
        rememberMe: rememberMe ? '1' : '0',
      });
  
      // if (response.code === 2000) {
        if (response && typeof response === 'object') {
          if (response.accessToken) {
            onLoginSuccess({
              email: email,
              accessToken: response.accessToken,
            });
            onClose();
          } else {
            throw new Error('Access token missing in response');
          }
        } else {
          throw new Error('Invalid response format');
        }
      // } else {
      //   setError(response.message || 'Login failed. Please check your credentials.');
      // }
    
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      console.error('Error during login:', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Login"
      onSubmit={handleLogin}
      submitLabel="Login"
      submitLoading={loading}
      submitDisabled={loading}
    >
      <TextField
        label="Username"
        type="text"
        fullWidth
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
            '&.Mui-focused': {
              color: '#52070c',
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#52070c',
            },
            '&:hover fieldset': {
              borderColor: '#52070c',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#52070c',
            },
          },
        }}
      />
      <TextField
        label="Password"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        variant="outlined"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
            '&.Mui-focused': {
              color: '#52070c',
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#52070c',
            },
            '&:hover fieldset': {
              borderColor: '#52070c',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#52070c',
            },
          },
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            sx={{
              color: '#52070c',
              '&.Mui-checked': {
                color: '#52070c',
              },
            }}
          />
        }
        label="Remember me"
        sx={{
          fontFamily: 'PT Sans',
          color: '#52070c',
        }}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </CustomDialog>
  );
}

export default LoginDialog;
