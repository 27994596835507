import React from 'react';
import { Box, Typography } from '@mui/material';
import ArticleCarousel from '../articlecarousel/ArticleCarousel';

function Mid5() {
  return (
    <Box
      sx={{
        paddingTop: '100px',
        paddingBottom: '100px',
        textAlign: 'center',
        width: '100%',
        overflow: 'hidden', 
        boxSizing: 'border-box', 
      }}
    >
      <Typography 
        variant="h1"
        sx={{
          paddingBottom: '50px', 
          fontSize: '40px'
        }}
      >
        Be Inspired
      </Typography>

      <ArticleCarousel />
    </Box>
  );
}

export default Mid5;
